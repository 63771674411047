@-moz-keyframes spin {
  from {-moz-transform: rotate(0deg);}
  to {-moz-transform: rotate(360deg);}
}
@-webkit-keyframes spin {
  from {-webkit-transform: rotate(0deg);}
  to {-webkit-transform: rotate(360deg);}
}
@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}

@-moz-keyframes flash {
  from { opacity: 1 }
  to { opacity: 0.6 }
}
@-webkit-keyframes flash {
  from { opacity: 1 }
  to { opacity: 0.6 }
}
@keyframes flash {
  0% { opacity: 1 }
  50% { opacity: 0.2 }
  100% { opacity: 1 }
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.9;
  background: #fff;
  z-index: 1;
}



.loading__spin {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 122px;
  height: 122px;
  margin: -61px 0 0 -61px;
  animation: spin 0.6s infinite linear;
  mask-image: url("data:image/svg+xml,%3Csvg width='122' height='122' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 61c0 33.137 26.863 60 60 60s60-26.863 60-60S94.137 1 61 1' stroke='%23005F3D'/%3E%3C/svg%3E");
  background-color: var( --brand-color );
}

.loading__gift {
  width: 80px;
  height: 80px;
  animation: flash 2s infinite ease-in-out;
  transition: opacity 0.4s linear;
  mask-image: url("data:image/svg+xml,%3Csvg width='80' height='80' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M25 7.167a7.833 7.833 0 0 0 0 15.666h14.372l-.023-.095a31.616 31.616 0 0 0-.529-1.858c-.488-1.542-1.258-3.593-2.383-5.64C34.174 11.128 30.555 7.168 25 7.168zM6.666 22.833h14.251a8.831 8.831 0 0 1-2.163-14.08A8.833 8.833 0 0 1 25 6.168c6.111 0 9.992 4.372 12.313 8.592 1.166 2.12 1.96 4.236 2.46 5.82.084.262.16.511.227.743.068-.232.143-.48.226-.744.502-1.583 1.295-3.698 2.46-5.819C45.009 10.539 48.89 6.167 55 6.167a8.834 8.834 0 0 1 4.082 16.666h14.751V40.5h-6.666v33.333H12.833V40.5H6.166V22.833h.5zM40.5 40.5h25.667v32.333H40.5V40.5zm0-1h32.333V23.833H40.5V39.5zm-1-15.667V39.5H7.166V23.833H39.5zm0 16.667v32.333H13.833V40.5H39.5zM55 22.833H40.627l.024-.095a31.68 31.68 0 0 1 .529-1.858c.488-1.542 1.258-3.593 2.383-5.64C45.825 11.128 49.445 7.168 55 7.168a7.833 7.833 0 1 1 0 15.666z' fill='%23005F3D'/%3E%3C/svg%3E");
  background-color: var( --brand-color );
}
