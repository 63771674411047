.footer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  @media screen and (min-width: 900px) {
    margin-top: auto;
    flex-direction: row;
    justify-content: center;
  }
  &__item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    font-size: 11px;
    line-height: 13px;
    font-style: normal;
    font-weight: 400;
    &:first-child {
      margin-bottom: 12px;
      @media screen and (min-width: 900px) {
        margin-bottom: 0;
        margin-right: 32px;
      }
    }
  }
  &__link {
    padding: 0;
    font-family: var( --main-font );
    font-size: 11px;
    line-height: 13px;
    font-style: normal;
    font-weight: 400;
    color: var( --textColorSecondary );
    text-decoration-line: underline;
    text-decoration-style: solid;
    background-color: transparent;
    border: none;
    appearance: none;
    cursor: pointer;
    &:focus-visible {
      outline: 2px solid var(--brand-color);
      outline-offset: 2px;
      border-radius: 2px;
    }
  }
  &__link-slash {
    font-size: 14px;
    font-weight: 400;
    padding: 0 8px;
  }
  &_theme-default {
    padding-top: 32px;
    margin-bottom: 20px;
    color: var( --textColorSecondary );
    font-family: var( --main-font );
    @media screen and ( min-width: 900px ) {
      padding-top: 80px;
      margin-bottom: 40px;
    }
  }
}
.footer-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  font-family: var( --main-font );
  /* убираем тут скроллбар для Windows */
  /* ie 10+ */
  -ms-overflow-style: none;
  // Chromium
  &::-webkit-scrollbar {
    width: 0;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    outline: none;

    // firefox scrollbar
    scrollbar-color: #E0E0E0 rgba(224, 224, 224, 0.5); // цвет ползунка +  цвет скроллбара
    scrollbar-width: thin;

    // scrollbar for Safari
    &::-webkit-scrollbar {
      //вся полоса прокрутки
      width: 8px;
      background-color: rgba(224, 224, 224, 0.5);
      border-radius: 4px;
    }
    &::-webkit-scrollbar-button {
      //кнопки на полосе прокрутки (стрелки, указывающие вверх и вниз, которые прокручивают одну строку за раз)
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      //перетаскиваемый дескриптор прокрутки
      background-color: #E0E0E0;
      border-radius: 4px;
    }
  }

  // terms-of-use
  &__title-tou {
    position: fixed;
    top: 0;
    left: 24px;
    transform: translateY( -50% );
    padding: 10px;
    margin-bottom: 0;
    max-width: 250px;
    font-size: 24px;
    line-height: 29px;
    font-style: normal;
    font-weight: 700;
    color: #FFFFFF;
    background-color: var( --brand-color );
    border-radius: 4px;
    @media screen and ( min-width: 900px ) {
      position: static;
      transform: translateY( 0 );
      padding: 0;
      margin-bottom: 8px;
      max-width: 100%;
      font-size: 28px;
      line-height: 34px;
      color: var( --textColorMain );
      background-color: transparent;
    }
  }
  &__subtitle-tou {
    margin-top: 0;
    margin-bottom: 10px;
    text-align: left;
    font-size: 18px;
    line-height: 22px;
    font-style: normal;
    font-weight: 700;
    color: var( --textColorMain );
    @media screen and (min-width: 900px) {
      font-size: 20px;
      line-height: 24px;
    }
  }
  &__paragraph-tou {
    margin-top: 0;
    margin-bottom: 16px;
    text-align: left;
    font-size: 15px;
    line-height: 21px;
    font-style: normal;
    font-weight: 400;
    color: var( --textColorSecondary );
    @media screen and ( min-width: 900px ) {
      margin-bottom: 32px;
    }
  }

  // how it works
  &__title-hiw {
    position: fixed;
    top: 0;
    left: 24px;
    transform: translateY( -50% );

    padding: 10px;
    margin-bottom: 0;
    max-width: 250px;
    font-size: 24px;
    line-height: 29px;
    font-style: normal;
    font-weight: 700;
    color: #FFFFFF;
    background-color: var( --brand-color );
    border-radius: 4px;
    @media screen and ( min-width: 900px ) {
      position: static;
      transform: translateY( 0 );

      padding: 0;
      margin-bottom: 32px;
      max-width: 100%;
      font-size: 28px;
      line-height: 34px;
      color: var( --textColorMain );
      background-color: transparent;
    }
  }
  &__subtitle-hiw {
    margin-top: 0;
    margin-bottom: 16px;
    text-align: left;
    font-size: 24px;
    line-height: 29px;
    font-style: normal;
    font-weight: 700;
    color: var( --textColorMain );
    @media screen and (min-width: 900px) {
      margin-bottom: 32px;
      font-size: 28px;
      line-height: 34px;
    }
  }
  &__paragraph-hiw {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 15px;
    line-height: 21px;
    font-style: normal;
    font-weight: 400;
    color: var( --textColorSecondary );
    @media screen and (min-width: 900px) {
      margin-bottom: 32px;
    }
    &_bold {
      margin-top: 0;
      margin-bottom: 10px;
      font-size: 18px;
      line-height: 22px;
      font-style: normal;
      font-weight: 700;
      color: var( --textColorMain );
      @media screen and (min-width: 900px) {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
  &__link-hiw {
    font-size: 15px;
    line-height: 21px;
    font-style: normal;
    font-weight: 400;
    color: var( --brand-color );
    text-decoration: underline;
    text-decoration-color: var( --brand-color );
    cursor: pointer;
    &:focus-visible {
      outline: 2px solid var(--brand-color);
      outline-offset: 2px;
      border-radius: 2px;
    }
  }
}
