.confetti {
  margin-left: -16px;
  margin-right: -16px;
  padding: 20px 16px 16px;
  display: block;
  max-width: 610px;
  background-image: url('../../../image/confetti-mobile-2x.png');
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (min-width: 900px) {
    margin: 0 auto;
    padding: 32px 27px 39px;
    background-image: url('../../../image/confetti-desktop-2x-540.png');
  }
  @media screen and (min-width: 1000px) {
    background-image: url('../../../image/confetti-desktop-2x.png');
  }
}
.copy-button {
  padding: 7px 0px 7px 12px;
  display: grid;
  grid-template-columns: 1fr 38px;
  grid-template-rows: 1fr 1fr;
  width: 100%;
  height: 48px;

  background-color: #FFFFFF;
  border: 1px solid rgba(54, 120, 220, 0.3);
  box-shadow: 0px 8px 27px var(--brand-color-opacity-30);
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  @media screen and (min-width: 900px) {
    grid-template-columns: 1fr auto;
    padding: 13px 15px;
    height: 66px;
    border-radius: 6px;
  }
  &__text {
    grid-area: 1/ 1/ 2 /2;
    align-self: flex-end;
    justify-self: flex-start;

    font-size: 11px;
    line-height: 13px;
    font-style: normal;
    font-weight: 400;
    color: var(--textColorMain);
    @media screen and (min-width: 900px) {
      font-size: 14px;
      line-height: 17px;
    }
  }
  &__url-area {
    grid-area: 2/ 1/ 3 /2;
    align-self: center;
    justify-self: flex-start;
    width: 100%;
    max-width: 252px;
    overflow: hidden;
    text-overflow: ellipsis;
    @media screen and (min-width: 900px) {
      max-width: 420px;
    }
  }
  &__url-text {
    margin: 0;
    display: inline;

    font-size: 15px;
    line-height: 21px;
    font-style: normal;
    font-weight: 400;
    color: var(--brand-color);

    white-space: nowrap;
    overflow: hidden;
    @media screen and (min-width: 900px) {
      font-size: 18px;
      line-height: 23px;
    }
  }
  &__image-block {
    grid-area: 1/ 2/ 3 /3;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  &__copy-mobile {
    display: block;
    width: 16px;
    height: 20px;

    background-color: var(--brand-color);
    -mask-image: url("data:image/svg+xml,%3Csvg width='16' height='20' viewBox='0 0 16 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.7895 0.736816H1.68421C0.757895 0.736816 0 1.49471 0 2.42103V14.2105H1.68421V2.42103H11.7895V0.736816ZM14.3158 4.10524H5.05263C4.12632 4.10524 3.36842 4.86313 3.36842 5.78945V17.5789C3.36842 18.5052 4.12632 19.2631 5.05263 19.2631H14.3158C15.2421 19.2631 16 18.5052 16 17.5789V5.78945C16 4.86313 15.2421 4.10524 14.3158 4.10524ZM14.3158 17.5789H5.05263V5.78945H14.3158V17.5789Z' fill='%233678DC'/%3E%3C/svg%3E");
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='16' height='20' viewBox='0 0 16 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.7895 0.736816H1.68421C0.757895 0.736816 0 1.49471 0 2.42103V14.2105H1.68421V2.42103H11.7895V0.736816ZM14.3158 4.10524H5.05263C4.12632 4.10524 3.36842 4.86313 3.36842 5.78945V17.5789C3.36842 18.5052 4.12632 19.2631 5.05263 19.2631H14.3158C15.2421 19.2631 16 18.5052 16 17.5789V5.78945C16 4.86313 15.2421 4.10524 14.3158 4.10524ZM14.3158 17.5789H5.05263V5.78945H14.3158V17.5789Z' fill='%233678DC'/%3E%3C/svg%3E");
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    mask-size: contain;
    -webkit-mask-size: contain;
    @media screen and (min-width: 900px) {
      display: none;
    }
  }
  &__copy-desktop {
    display: none;
    @media screen and (min-width: 900px) {
      padding: 0 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      height: 40px;

      font-family: inherit;
      font-size: 18px;
      line-height: 22px;
      font-style: normal;
      font-weight: 700;

      color: #FFFFFF;
      background: var(--brand-color);
      border: 2px solid transparent;
      border-radius: 5px;
      box-sizing: border-box;
    }
  }
  &:focus-visible .copy-button__copy-desktop{
    border-color: #2D2C2C;
  }
}
