.select {
  position: relative;
  width: 100%;
  &__label {
    position: absolute;
    left: 12px;
    top: 7px;
    font-family: var( --main-font );
    font-size: 11px;
    line-height: 13px;
    font-style: normal;
    font-weight: 400;
    color: var( --textColorSecondary );
  }
  &__element {
    width: 100%;
    padding: 19px 46px 6px 12px;
    font-family: var( --main-font );
    font-size: 15px;
    line-height: 21px;
    font-style: normal;
    font-weight: 400;
    color: var( --textColorMain );
    background-color: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    outline: none;
    cursor: pointer;
    appearance: none;
    &_focus {
      border-color: var( --brand-color );
    }
    &:hover {
      border-color: var( --textColorSecondary );
    }
  }
  &__checkmark {
    position: absolute;
    top: 19px;
    right: 12px;
    display: block;
    content: "";
    width: 8px;
    height: 8px;
    border: 2px solid #000000;
    border-top-width: 0;
    border-left-width: 0;
    transform: rotate(45deg);
  }
  &__element_focus + .select__checkmark {
    top: 23px;
    transform: rotate(225deg);
  }
  &_full-size {
    width: 100%;
  }
  &_half-media-size {
    width: 100%;
    @media screen and (min-width: 900px) {
      width: calc(50% - 4px);
    }
  }
  &_half-size {
    width: calc(50% - 4px);
  }
  &_outer-size {
    margin-bottom: 8px;
    &_0 {
      margin-bottom: 0;
    }
    &_16-24 {
      margin-bottom: 16px;
      @media screen and ( min-width: 900px ) {
        margin-bottom: 24px;
      }
    }
  }
}
