.button {
  display: block;
  border: none;
  cursor: pointer;
  outline: none;
  font-family: var( --main-font );
  // default
  &_variant-default {
    color: #FFFFFF;
    background-color: var( --brand-color );
    border-color: transparent;
    border-radius: 4px;
    outline: none;
    &:focus-visible {
      border-color: #2D2C2C;
    }
    &:hover {
      filter: saturate(123%);
    }
  }
  &_disabled-default {
    background-color: var(--button-disabled-color);
    cursor: default;
    &:hover {
      filter: none;
    }
    &:focus {
      border-color: transparent;
      box-shadow: none;
    }
  }
  // props.revers-color
  &_variant-revers-color {
    color: var( --brand-color );
    background-color: #FFFFFF;
    border-color: var( --brand-color );
    &:focus {
      border-width: 2px;
    }
    &:hover {
      border-width: 2px;
    }
  }
  // props.variant
  &_variant-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 400px;
    height: 52px;
    padding: 10px 12px;
    font-size: 12px;
    line-height: 14px;
    font-style: normal;
    font-weight: 400;
    color: var( --textColorMain );
    background-color: #FFFFFF;
    text-decoration: none;
    outline: none;
    @media screen and (min-width: 900px) {
      font-size: 14px;
      line-height: 17px;
    }
    &:focus-visible {
      color: var( --textColorSecondary );
      text-decoration: underline;
    }
    &:hover {
      color: var( --textColorSecondary );
    }
  }
  // for wrap-type-selector
  &_variant-wrap-type-selector {
    @media screen and ( max-width: 900px ) {
      position: absolute;
      bottom: 20px;
      left: 20px;
      width: calc(100% - 40px)!important;
    }
  }
  &_variant-recipient-disabled {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 400px;
    height: 52px;
    font-size: 12px;
    line-height: 14px;
    font-style: normal;
    font-weight: 400;
    color: var( --textColorSecondary );
    background: rgba(224, 224, 224, 0.5);
    border: 2px solid transparent;
    @media screen and (min-width: 900px) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  // props.size
  &_size-default {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 12px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 400px;
    height: 52px;

    font-size: 14px;
    line-height: 17px;
    font-style: normal;
    font-weight: 700;

    letter-spacing: 1px;
    text-transform: uppercase;
    border-width: 2px;
    border-style: solid;
    @media screen and (min-width: 900px) {
      font-size: 16px;
      line-height: 19px;
    }
  }
  &_size-recipient-giftbox {
    margin: 0 auto 32px;
    padding: 0;
    width: 228px;
    height: 52px;
    font-size: 14px;
    line-height: 17px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 4px;
    border-width: 2px;
    border-style: solid;
    @media screen and (min-width: 900px) {
      margin-top: 23px;
      margin-bottom: 52px;
      font-size: 18px;
      line-height: 22px;
    }

  }
//? пока не использовано
  &_pressed-down {
    @media screen and (max-width: 900px) {
      margin-top: auto;
    }
  }
  //? пока не использовано
  &_alternatives-page {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    @media screen and (min-width: 900px) {
      width: 50%;
    }
    &::after {
      @media screen and (max-width: 900px) {
      position: absolute;
      left: 0;
      bottom: 0;
      display: block;
      width: 374px; //394
      height: 306px; //66
      content: '';
      border: 2px solid var( --main-color );
      border-top: none;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      @media screen and (max-width: 394px) {
        width: 100%;
      }
      }
    }
  }
}
