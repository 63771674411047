.form {
  display: block;
  // for mobile height
  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  //\/
  @media screen and (min-width: 900px) {
    width: 100%;
    max-width: 400px;
  }
  // \/
  &__fieldset {
    position: relative;
    display: flex;
    margin: 0;
    padding: 0;
    border: none;
    flex-direction: column;
    //for mobile height
    @media screen and (max-width: 900px) {
      flex-grow: 1;
    }
    //?
    &_row-media {
      @media screen and (min-width: 900px) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }

    // доп обложка для recipient-delivery-info
    &_row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: flex-start;
    }
    //?/посмотри проверь + props
    &_size-top {
      @media screen and (max-width: 900px) {
        margin-top: 10px;
      }
    }
  }
  //\/
  &__subtitle {
    margin-bottom: 16px;
    margin-top: 0;
    font-family: var( --main-font );
    font-size: 24px;
    line-height: 29px;
    font-style: normal;
    font-weight: 700;
    color: var( --textColorMain );
    @media screen and (min-width: 900px) {
      margin-bottom: 24px;
      font-size: 28px;
      line-height: 34px;
    }
    &_hidden {
      display: none;
      @media screen and (min-width: 900px) {
        display: block;
      }
    }
  }
  //\/
  &__legend-wrap {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
    min-width: 100%;
    font-family: var( --main-font );
    //\/
    &_hidden {
      display: none;
    }
  }
  //\/
  &__legend {
    padding: 0;
    font-size: 18px;
    line-height: 22px;
    font-style: normal;
    font-weight: 700;
    color: var( --textColorMain );
    @media screen and (min-width: 900px) {
      font-size: 20px;
      line-height: 24px;
    }
  }
  //\/ for safari position
  &__legend-desc {
    align-self: flex-end;
    margin-left: auto;
    font-size: 10px;
    line-height: 12px;
    font-style: normal;
    font-weight: 700;
    color: var( --textColorSecondary );
  }
  &_theme-wrap-type-selector {
    max-width: 680px;
    margin: 0 auto;
  }
  //
  &_outer-size-top {
    @media screen and (min-width: 900px) {
      padding-top: 40px;
    }
  }
  // for recipient flow only
  &_outer-size-bottom {
    margin-bottom: 20px;
    @media screen and ( min-width: 900px ) {
      margin-bottom: 24px;
    }
  }
}
