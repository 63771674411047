.products {
  padding: 0 10px 10px;
  display: block;
  @media screen and (min-width: 900px) {
    padding: 0;
  }
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(3, 95px);
    grid-gap: 10px;
    @media screen and (min-width: 900px) {
      grid-template-columns: repeat(3, 143px);
      grid-gap: 0;
    }
  }
  &__item {
    padding: 5px 8px;
    display: block;
    background-color: #FFFFFF;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0px 4px 16px rgba(77, 95, 113, 0.16);
    @media screen and (min-width: 900px) {
      padding: 12px 13px;
      box-shadow: none;
      border-width: 2px;
    }
    &_selected {
      background-color: #F1F2ED;
      border: 1px solid var(--brand-color);
      @media screen and (min-width: 900px) {
        border-width: 2px;
      }
    }
  }
  &__image-wrapper {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 109px;
    background-color: #FFFFFF;
    @media screen and (min-width: 900px) {
      height: 116px;
    }
  }
  &__image {
    width: 100%;
    height: auto;
    max-height: 109px;
    object-fit: contain;
    @media screen and (min-width: 900px) {
      max-height: 116px;
    }
  }
  &__name {
    display: block;
    font-family: var( --main-font );
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    text-align: center;
    color: #667280;
    @media screen and (min-width: 900px) {
      font-size: 14px;
      line-height: 17px;
    }
  }
  &__button-next {
    margin: 20px 0;
    padding: 0;

    display: block;
    width: 100%;
    height: 52px;

    font-family: inherit;
    font-size: 20px;
    line-height: 24px;
    font-style: normal;
    font-weight: 900;
    color: #FFFFFF;
    background-color: var( --brand-color );
    border: none;
    border-radius: 4px;
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    outline: none;

    &:focus-visible {
      outline: 2px solid #2D2C2C;
      outline-offset: -2px;
    }
    @media screen and (min-width: 900px) {
      margin: 30px 0;
    }
  }
  &__item-options {
    margin-top: 20px;
  }
  &__button-refresh-items {
    margin: 0 auto;
    padding: 4px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-family: inherit;
    font-size: 14px;
    line-height: 17px;
    font-style: normal;
    font-weight: 700;
    color: var( --brand-color );
    border: none;
    background-color: #FFFFFF;
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    outline: none;
    &:focus-visible {
      outline: 2px solid var(--brand-color);
      outline-offset: 2px;
      border-radius: 2px;
    }
  }
  &__button-refresh-items-image {
    margin-right: 8px;
    display: block;
    width: 16px;
    height: 16px;
    background-color: var(--brand-color);
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 17 16' fill='none'%3E%3Cpath d='M14.1498 2.35C12.6998 0.9 10.7098 0 8.49977 0C4.07977 0 0.509766 3.58 0.509766 8C0.509766 12.42 4.07977 16 8.49977 16C12.2298 16 15.3398 13.45 16.2298 10H14.1498C13.3298 12.33 11.1098 14 8.49977 14C5.18977 14 2.49977 11.31 2.49977 8C2.49977 4.69 5.18977 2 8.49977 2C10.1598 2 11.6398 2.69 12.7198 3.78L9.49977 7H16.4998V0L14.1498 2.35Z' fill='%23899265'/%3E%3C/svg%3E");
    mask-repeat: no-repeat;
    mask-size: contain;
  }
}
