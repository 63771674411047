.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  // back link
  &__back-link {
    visibility: hidden;
    display: flex;
    align-items: center;
    flex-basis: 33.33%;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    @media screen and ( min-width: 440px ) {
      visibility: visible;
    }
    &_hidden {
      visibility: hidden;
    }
    &:focus {
      text-decoration: underline;
      text-decoration-color: var(--textColorSecondary);
    }
  }
  &__back-link-img {
    width: 24px;
    height: 24px;
    margin-right: 16px;
    background-color: var( --textColorSecondary );
    mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 12H5M10 18l-6-6 6-6' stroke='%23000' stroke-width='2' stroke-linecap='square'/%3E%3C/svg%3E");
    mask-repeat: no-repeat;
    mask-size: contain;
  }
  &__back-link-text {
    display: none;
    @media screen and ( min-width: 900px ) {
      display: block;
      font-family: var( --main-font );
      font-size: 14px;
      line-height: 17px;
      font-style: normal;
      font-weight: 400;
      color: var( --textColorSecondary );
    }
  }
  // hover
  &__back-link:hover .header__back-link-img {
    background-color: var( --textColorMain );
  }
  &__back-link:hover .header__back-link-text {
    color: var( --textColorMain );
  }
  // logo
  &__logo {
    display: block;
    flex-basis: 33.33%;
    &_default {
      display: none;
      @media screen and ( min-width: 900px ) {
        display: block;
        width: 100%;
        max-width: 180px;
        height: auto;
        max-height: 52px;
        object-fit: contain;
      }
    }
    &_text {
      display: none;
      @media screen and (min-width: 900px) {
        display: block;
        font-family: var( --main-font );
        font-size: 16px;
        line-height: 19px;
        font-style: normal;
        font-weight: 700;
        color: var( --textColorMain );
        text-align: center;
        text-transform: uppercase;
      }
    }
  }
  // giftmagic logo
  &__giftmagic-logo-box {
    flex-basis: 33.33%;
  }
  &__giftmagic-logo-image {
    display: block;
    margin-left: auto;
    width: 98px;
    height: 12px;
    background-color: #FFFFFF;
    background-image: url("../../../image/giftmagic-text-logo.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 0;
  }
  &__giftmagic-logo-image-rebranding {
    display: block;
    margin-left: auto;
    width: 103px;
    height: 52px;
    object-fit: contain;
  }

  // Themes
  &_theme-default {
    padding: 14px 20px;
    height: 52px;
    @media screen and ( min-width: 900px ) {
      height: 72px;
      margin-bottom: 40px;
      padding: 20px 40px 0;
    }
  }
  &_theme-rebranding {
    padding: 20px 24px;
    @media screen and (min-width: 900px) {
      height: 72px;
      margin-bottom: 40px;
      padding: 20px 40px 0;
    }
  }
  // for recipient-delivery-info &
  &_size-outer-24px {
    @media screen and ( min-width: 900px ) {
      margin-bottom: 24px;
    }
  }
}
