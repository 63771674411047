.check {
  display: flex;
  font-family: var( --main-font );
  cursor: pointer;

  &_variant-opt-in {
    margin-bottom: 16px;
    align-items: center;
    justify-content: flex-start;
    @media screen and ( min-width: 900px ) {

    }
  }
  &__input {
    position: absolute;
    width :1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    appearance: none;
  }
  &__box {
    margin-right: 8px;
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    background-color: #FFFFFF;
    border: 1px solid #939393;
    border-radius: 4px;
    &:hover {
      border-color: var(--brand-color);
    }
  }
  &__text {
    font-size: 11px;
    line-height: 13px;
    font-style: normal;
    font-weight: 400;
    color: var( --textColorMain );
  }
  &__link {
    text-decoration: underline;
    text-decoration-color: var( --brand-color );
    text-decoration-style: solid;
    color: var( --brand-color );
    cursor: pointer;
  }
}

.check__input:checked + .check__box {
  background-color: var( --brand-color );
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='10' viewBox='0 0 13 10' fill='none'%3E%3Cpath d='M1 4.5L4.5 8L11.5 1' stroke='white' stroke-width='2'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 11px auto;
  background-position: center;
  border-color: var(--brand-color);
}
.check__input:checked:hover + .check__box {
  filter: saturate(123%);
}
