.certificate {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  padding: 0 10px;
  @media screen and  (min-width: 900px){
    padding: 0;
  }
  &__card {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  &__card-image {
    object-fit: contain;
    height: 115px;
    margin-bottom: 10px;
    @media screen and (min-width: 900px) {
      margin-bottom: 15px;
    }
  }
  &__card-value {
    position: absolute;
    top: 93px;
    left: 136px;
    font-family: var( --main-font );
    font-size: 8px;
    line-height: 9.5px;
    color: #ffffff;
    @media screen and (min-width: 900px) {
      left: 163px;
    }
  }
  &__card-text {
    margin-bottom: 5px;
    font-family: var( --main-font );
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    color: #141414;
    text-align: center;
    @media screen and (min-width: 900px) {
      margin-bottom: 10px;
    }
  }
  &__form {
    display: block;
    margin-bottom: 15px;
    @media screen and (min-width: 900px) {
      padding-right: 8px;
      padding-left: 8px;
      margin-bottom: 10px;
    }
  }
  &__scrollbar {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-left: -10px;
    margin-right: -10px;
    overflow: scroll hidden;
    padding: 0 10px 10px;
    @media screen and (min-width: 900px) {
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 0;
      overflow-x: hidden;
    }
  }
  &__wrap-radio {
    margin-right: 3px;
    @media screen and (min-width: 900px) {
      margin-right: 2%;
      margin-bottom: 10px;
      &:nth-of-type(6n) {
        margin-right: 0;
      }
    }
  }
  &__input {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 0px;
    height: 0px;
    margin: -1px;
    &:checked + .certificate__label {
      border-color: var( --brand-color );
    }
    &:focus + .certificate__label {
      border-color: var( --brand-color );
    }
  }
  &__label {
    display: block;
    width: 60px;
    height: 32px;
    font-family: var( --main-font );
    font-size: 14px;
    line-height: 32px;
    font-weight: 700;
    text-align: center;
    color: #141414;
    border: 1px solid rgba(108, 131, 146, .3);
    border-radius: 4px;
    cursor: pointer;
  }
  &__button-send {
    display: block;
    width: 158px;
    padding: 9px 25px;
    margin: auto auto 8px;
    font-family: var( --main-font );
    font-size: 16px;
    line-height: 24px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
    border: none;
    border-radius: 3px;
    background-color: var( --brand-color );
    cursor: pointer;
    transition: all .2s linear;
    appearance: none;
    -webkit-appearance: none;
    @media screen and (min-width: 900px) {
      width: 182px;
      padding: 9px 25px;
      margin-bottom: 25px;
    }
  }
}
