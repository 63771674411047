// временные стили
.container {
  width: 100%;
  @media screen and (max-width: 900px) {
    flex-grow: 1;
  }
  @media screen and (min-width: 900px) {
    margin-bottom: 32px;
  }
  &_mobile {
    display: block;
    box-shadow: 0px 0px 24px rgba(95, 95, 95, 0.32);
    border-radius: 4px;
    @media screen and (min-width: 900px) {
      display: none;
    }
  }
  &_desktop {
    display: none;
    @media screen and (min-width: 900px) {
      display: block;
    }
  }
}
//---------------------
// мобильная версия
.mobile-radio {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  &__label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
    height: 158px;
    padding: 27px 10px 0;
    border: 2px solid transparent;
    border-radius: 4px;
    background: #FFFFFF;
    &_left {
      border-top-right-radius: 0;
      border-right: 2px solid transparent;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-width: 0;
    }
    &_left-checked {
      position: relative;
      border-top-color: var( --brand-color );
      border-top-right-radius: 4px;
      border-right: 2px solid var( --brand-color );
      border-bottom-color: transparent;
      border-bottom-right-radius: 0;
      border-left-color: var( --brand-color );
      // заплатка
      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: -3px;
        left: -2px;
        height: 5px;
        width: calc(100% + 2px);
        background-color: #FFFFFF;
        border-left: 2px solid var( --brand-color );
      }
    }
    &_right {
      border-top-left-radius: 0;
      border-bottom-width: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 2px solid transparent;
    }
    &_right-checked {
      position: relative;
      border-top-color: var( --brand-color );
      border-top-left-radius: 4px;
      border-right-color: var( --brand-color );
      border-bottom-color: transparent;
      border-left: 2px solid var( --brand-color );
      // заплатка
      &:after {
        content: '';
        display: block;
        position: absolute;
        right: -2px;
        bottom: -3px;
        width: calc(100% + 2px);
        height: 5px;
        background-color: #FFFFFF;
        border-right: 2px solid var( --brand-color );
      }
    }
  }
  &__input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    clip: rect(0, 0, 0, 0);
    appearance: none;
    -webkit_appearance: none;
  }
  //\/
  &__img {
    margin-bottom: 8px;
    width: 50px;
    height: 50px;
    background-color: var( --textColorSecondary );
    mask-repeat: no-repeat;
    mask-size: contain;
    &_one-address {
      mask-image: url('../../../image/one-address.svg');
    }
    &_many-address {
      mask-image: url('../../../image/many-address.svg');
    }
    &_checked {
      background-color: var( --brand-color );
    }
  }
  &__text {
    font-family: var( --main-font );
    font-size: 14px;
    line-height: 17px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    &_checked {
      color: var( --brand-color );
    }
    @media screen and (min-width: 900px) {
      font-size: 16px;
      line-height: 19px;
    }
  }
}
// стили списка
.mobile-list {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 30px 18px 18px;
  width: 100%;
  border: 2px solid var( --brand-color );
  border-radius: 4px;
  &__box {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: var( --main-font );
    font-size: 15px;
    line-height: 21px;
    font-style: normal;
    font-weight: 400;
    color: var( --textColorMain );
    list-style: none;
  }
  &__item {
    position: relative;
    display: block;
    max-width: 330px;
    padding-left: 24px;
    border-bottom: 1px solid #F2F2F2;
    &::after {
      position: absolute;
      top: 8px;
      left: 8px;
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      background-color: var( --textColorMain );
      border-radius: 50%;
    }
    &:not(:nth-of-type(1)) {
      margin-top: 8px;
    }
    &:not(:last-of-type) {
      padding-bottom: 8px;
    }
    &:last-of-type {
      margin-bottom: 64px;
      border-bottom: none;
    }
  }
}
//---------------------
//  десктопная версия
.radio {
  display: block;
  width: 100%;
  &:nth-of-type(1) {
    margin-bottom: 16px;
  }

  &__label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 26px;
    border-radius: 8px;
    border: 2px solid var( --textColorSecondary );
    cursor: pointer;

    &_checked {
      border-color: var( --brand-color );
    }
  }
  &__input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    clip: rect(0, 0, 0, 0);
    appearance: none;
  }
  &__pseudo-input {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    border: 2px solid #707277;
    border-radius: 50%;
    margin-right: 28px;
    &::after {
      display: none;
    }
  }
  &__img {
    flex-shrink: 0;
    width: 110px;
    height: 110px;
    margin-right: 28px;
    background-color: var( --textColorSecondary );
    mask-repeat: no-repeat;
    mask-size: contain;
    &_one-address {
      mask-image: url('../../../image/one-address.svg');
    }
    &_many-address {
      mask-image: url('../../../image/many-address.svg');
    }
  }
}
.radio__input:checked + .radio__pseudo-input {
  border-color: var( --brand-color );
  &::after {
    position: relative;
    top: 4px;
    left: 4px;
    content: "";
    display: block;
    width: 12.24px;
    height: 12.24px;
    border-radius: 50%;
    background-color: var( --brand-color );
  }
}
.radio__input:checked ~ .radio__img {
  background-color: var( --brand-color );
}

.text-box {
  width: 100%;
  font-family: var( --main-font );
  &__title {
    margin-top: 0;
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 17px;
    font-style: normal;
    font-weight: 700;
    color: var( --textColorMain );
    text-transform: uppercase;
    letter-spacing: 1px;
    @media screen and (min-width: 900px) {
      font-size: 16px;
      line-height: 19px;
    }
  }
  &__list {
    padding: 0;
    margin: 0;
    font-size: 15px;
    line-height: 21px;
    font-style: normal;
    font-weight: 400;
    color: var( --textColorMain );
    list-style: none;
  }
  &__item {
    position: relative;
    padding-left: 24px;
    &::after {
      position: absolute;
      left: 9px;
      top: 8px;
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      background-color: black;
      border-radius: 50%;
    }
    &:not(:last-of-type) {
    margin-bottom: 4px;
  }
  }
}
