/* autoprefixer grid: autoplace */
@mixin flexbox () {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

*,
::after,
::before {
    box-sizing: inherit;
}
:root {
  box-sizing: border-box;
}

// lato -default
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

// GT Eesti Pro Display UltraLight
@font-face {
  font-family: 'GT Eesti Pro Display';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src:
    local('GT Eesti Pro Display Light'),
    url('../fonts/GTEestiProDisplay-UltraLight.woff') format('woff2'),
    url('../fonts/GTEestiProDisplay-UltraLight.woff') format('woff');
}

// for ozon
// GT Eesti Pro Display light
@font-face {
  font-family: 'GT Eesti Pro Display';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src:
    local('GT Eesti Pro Display Light'),
    url('../fonts/GTEestiProDisplay-Light.woff') format('woff2'),
    url('../fonts/GTEestiProDisplay-Light.woff') format('woff');
}

// GT Eesti Pro Display normal
@font-face {
  font-family: 'GT Eesti Pro Display';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
  local('GT Eesti Pro Display Regular'),
  url('../fonts/GTEestiProDisplay-Regular.woff2') format('woff2'),
  url('../fonts/GTEestiProDisplay-Regular.woff') format('woff');
}

// GT Eesti Pro Display bold
@font-face {
  font-family: 'GT Eesti Pro Display';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
  local('GT Eesti Pro Display Regular'),
  url('../fonts/GTEestiProDisplay-Bold.woff2') format('woff2'),
  url('../fonts/GTEestiProDisplay-Bold.woff') format('woff');
}

// Montserrat for ozon
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;700&display=swap');

// Futura PT for Pollini
@font-face {
  font-family: 'Futura PT, sans-serif';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local('FuturaPT'),
    url('../fonts/FuturaPT-Medium.woff2') format('woff2'),
    url('../fonts/FuturaPT-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Futura PT, sans-serif';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    local('FuturaPT'),
    url('../fonts/FuturaPT-Bold.woff2') format('woff2'),
    url('../fonts/FuturaPT-Bold.woff') format('woff');
}

// Roboto for Giftosa
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  margin: 0;
}
#root {
  width: 100%;
  height: 100%;
}
.app {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  @media screen and (min-width: 900px){
    min-height: 100vh;
  }
}
.wrapper-for-mobile-height {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  @media screen and (max-width: 900px){
    min-height: 100vh;
    // add to more height
    //min-height: calc((var(--vh, 1vh)*100));
  }
}
.main {
  position: relative;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 900px) {
    flex-grow: 1;
  }
  &_theme-default {
    margin-left: 12px;
    margin-right: 12px;
    @media screen and ( min-width: 360px ) {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: 336px;
      margin-bottom: auto;
    }
    @media screen and ( min-width: 900px ) {
      max-width: 780px;
    }
  }
  &_theme-share {
    margin-left: 12px;
    margin-right: 12px;
    @media screen and ( min-width: 360px ) {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: 336px;
      margin-bottom: auto;
    }
    @media screen and ( min-width: 900px ) {
      max-width: 860px;
    }
    @media screen and ( min-width: 1070px ) {
      max-width: 990px;
    }
  }
  &_theme-recipient-giftbox {
    width: 100%;
    @media screen and ( min-width: 360px ) {
      margin-left: auto;
      margin-right: auto;
      width: 312px;
    }
    @media screen and ( min-width: 900px ) {
      width: 472px;
    }
  }
  &_theme-recipient {
    width: 100%;
    @media screen and ( min-width: 360px ) {
      margin-left: auto;
      margin-right: auto;
      max-width: 360px;
    }
    @media screen and ( min-width: 900px ) {
      max-width: 860px;
    }
  }
  &_theme-error {
    width: 100%;
    margin-bottom: 30px;
    @media screen and (min-width: 414px) {
      width: 414px;
      margin-left: auto;
      margin-right: auto;
    }
    @media screen and (min-width: 900px) {
      width: 770px;
    }
  }
  &_theme-alternative {
    margin-left: 12px;
    margin-right: 12px;
    @media screen and ( min-width: 360px ) {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: 328px;
      margin-bottom: auto;
    }
    @media screen and (min-width: 900px) {
      max-width: 430px;
      margin-bottom: 0;
    }
  }
}
.main-container {
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 900px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  &_theme-default {
    padding: 16px;
    box-shadow: 0px 0px 24px rgba(95, 95, 95, 0.32);
    border-radius: 8px;
    @media screen and (min-width: 900px) {
      padding: 0;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      box-shadow: none;
      border-radius: 0;
    }
  }
  &_theme-share {
    padding: 16px;
    box-shadow: 0px 0px 24px rgba(95, 95, 95, 0.32);
    border-radius: 8px;
    @media screen and (min-width: 900px) {
      padding: 0;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      box-shadow: none;
      border-radius: 0;
    }
  }
  &_theme-giftcard {
    margin-top: 24px;
    align-items: center;
    @media screen and (min-width: 900px) {
      margin-top: 0;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
    }
  }
}
.sender {
  &__tou {
    margin: 8px auto 0;
    display: block;
    padding: 10px;
    max-width: 400px;
    font-family: var(--main-font);
    font-size: 11px;
    line-height: 13px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    color: var(--textColorSecondary);
  }
}
.share {
  font-family: var(--main-font);
  text-align: center;
  &__info {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (min-width: 900px) {
      margin-bottom: 40px;
    }
  }
  &__info-title {
    margin: 0;
    margin-bottom: 12px;

    font-size: 28px;
    line-height: 34px;
    font-style: normal;
    font-weight: 700;
    color: var(--textColorMain);
    text-align: center;
    @media screen and (min-width: 900px) {
      margin-bottom: 9px;
    }
  }
  &__info-button {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: inherit;
    font-size: 16px;
    line-height: 19px;
    font-style: normal;
    font-weight: 700;
    color: var(--brand-color);
    background-color: #FFFFFF;

    border: none;
    cursor: pointer;
    outline: none;
    @media screen and (min-width: 900px) {
      font-size: 18px;
      line-height: 22px;
    }
    &:focus-visible {
      outline: 2px solid var(--brand-color);
      outline-offset: 2px;
      border-radius: 2px;
    }
  }
  &__info-button-image {
    margin-left: 6px;
    background-color: var(--brand-color);
    @media screen and (min-width: 900px) {
      margin-left: 4px;
    }
  }
  &__form {
    display: block;
    @media screen and (min-width: 900px) {
      padding-top: 30px;
      width: 100%;
      max-width: 540px;
    }
    @media screen and (min-width: 1000px) {
      max-width: 610px;
    }
  }
  &__form-title-wrapper {
    display: block;
    @media screen and (min-width: 900px) {
      padding-left: 27px;
      display: grid;
      grid-template-columns: 1fr 2fr;
    }
  }
  &__form-title {
    margin: 0;
    font-family: var(--main-font);
    font-size: 18px;
    line-height: 22px;
    font-style: normal;
    font-weight: 700;
    color: var(--textColorMain);
    text-align: center;
    @media screen and (min-width: 900px) {
      font-size: 32px;
      line-height: 38px;
      text-align: left;
    }
  }
  &__form-social-block {
    display: none;
    @media screen and (min-width: 900px) {
      position: relative;
      left: -28px;
      bottom: -6px;
      align-self: flex-end;
      display: block;
      width: 101px;
      height: auto;
      object-fit: cover;
    }
  }
  &__native-share-button {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 52px;

    font-family: inherit;
    font-size: 16px;
    line-height: 19px;
    font-style: normal;
    font-weight: 700;
    color: #FFFFFF;
    background-color: var(--brand-color);
    border-radius: 4px;
    border: 2px solid transparent;

    cursor: pointer;
    outline: none;
    box-sizing: border-box;
    @media screen and (min-width: 900px) {
      margin-left: 27px;
      padding-right: 20px;
      padding-left: 20px;
      width: auto;
    }
    &:focus-visible {
      border-color: #2D2C2C;
    }
  }
  &__native-share-button-image {
    margin-left: 10px;
  }
  &__fb-share {
    display: block;
  }
  &__fb-share-title {
    margin-top: 0;
    margin-bottom: 12px;
    font-size: 20px;
    line-height: 24px;
    font-style: normal;
    font-weight: 700;
    color: var(--textColorMain);
    text-align: left;
    @media screen and (min-width: 900px) {
      padding-left: 27px;
    }
  }
  &__fb-share-button {
    padding: 8px 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: inherit;
    font-size: 16px;
    line-height: 19px;
    font-style: normal;
    font-weight: 700;
    color: #FFFFFF;

    background-color: #3479EA;
    border-radius: 4px;
    border: 2px solid transparent;
    cursor: pointer;
    outline: none;
    @media screen and (min-width: 900px) {
      margin-left: 27px;
    }
    &:focus-visible {
      border-color: #2D2C2C;
    }
  }
  &__fb-share-button-image {
    margin-right: 8px;
  }

  &__back-shop-link {
    margin-top: 40px;
    margin-bottom: 16px;
    display: inline-block;
    font-size: 14px;
    line-height: 17px;
    font-style: normal;
    font-weight: 400;
    text-decoration-line: underline;
    color: var(--textColorMain);
    &:focus-visible {
      outline: 2px solid var(--brand-color);
      outline-offset: 2px;
      border-radius: 2px;
    }
  }
  &__float-block {
    position: fixed;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    opacity: 0;

    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: inherit;
    font-size: 18px;
    line-height: 22px;
    font-style: normal;
    font-weight: 700;
    border-radius: 8px;
    @media screen and (min-width: 900px) {
      top: 96px;
      left: auto;
      right: 40px;
      transform: translateX(0);
    }
    &_showed {
      transition: all .3s linear 0s;
      opacity: 1;

      width: calc(100% - 24px);
      max-width: 328px;
      @media screen and (min-width: 900px) {
        width: auto;
      }
    }
    &_link-copied {
      color: var(--textColorMain);
      background-color: #E0E0E0;
      border: 1px solid rgba(45, 44, 44, 0.2);
    }
    &_link-copied-image {
      margin-right: 8px;
    }
    &_link-send {
      color: #7CB342;
      background-color: #EFF6E8;
      border: 1px solid rgba(124, 179, 66, 0.2);
    }
    &_link-send-image {
      margin-left: 8px;
    }
  }
}
.recipient-delivery {
  &__tou {
    margin: 0 auto ;
    display: block;
    max-width: 400px;
    font-family: var(--main-font);
    font-size: 11px;
    line-height: 13px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    color: var(--textColorSecondary);
  }
}

// ROUTE.GIFT_BOX
.giftbox {
  &__button {
    text-transform: capitalize!important;
  }
  &__title {
    margin: 0 auto 16px;
    max-width: 312px;
    font-family: var( --main-font );
    font-size: 24px;
    line-height: 29px;
    font-style: normal;
    font-weight: 700;
    color: var( --textColorMain );
    text-align: center;
    @media screen and ( min-width: 900px ) {
      max-width: 472px;
      font-size: 28px;
      line-height: 34px;
    }
  }
  &__subtitle {
    margin: 0 auto;
    max-width: 312px;
    font-family: var( --main-font );
    font-size: 16px;
    line-height: 19px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    color: var( --textColorMain );
    @media screen and ( min-width: 900px ) {
      max-width: 472px;
      font-size: 18px;
      line-height: 22px;
    }
  }
}

// ROUTE.ACCEPT_FORM
.accept {
  font-family: var( --main-font );
  &__title {
    margin: 0 24px 24px;
    font-size: 24px;
    line-height: 29px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    @media screen and ( min-width: 900px ) {
      padding-left: 40px;
      padding-right: 40px;
      margin-left: auto;
      margin-right: auto;
      font-size: 28px;
      line-height: 34px;
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    padding: 16px;
    margin: 0 12px;
    box-shadow: 0px 0px 24px rgba(95, 95, 95, 0.32);
    border-radius: 8px;
    @media screen and ( min-width: 900px ) {
      margin: 0;
      padding: 0;
      box-shadow: none;
      border-radius: 0;
    }
  }
  &__tou {
    display: block;
    padding: 10px;
    margin-top: 8px;
    max-width: 400px;
    font-family: var( --main-font );
    font-size: 11px;
    line-height: 13px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    color: var( --textColorSecondary );
  }
  &__tou-link {
    display: inline-block;
    color: var( --textColorSecondary );
    cursor: pointer;
    &:focus-visible {
      outline: 2px solid var(--brand-color);
      outline-offset: 2px;
      border-radius: 2px;
    }
  }
}

// ROUTE.FINISH
.finish {
  font-family: var( --main-font );
  &__form-subtitle-desc {
    display: block;
    margin-bottom: 16px;
    font-size: 15px;
    line-height: 21px;
    font-style: normal;
    font-weight: 400;
  }
  &__image-button {
    margin-right: 4px;
    width: 24px;
    height: 24px;
    background-color: var( --textColorSecondary );
    mask-image: url("data:image/svg+xml,%3Csvg width='16' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5.5L5.5 10l9-9' stroke='%23fff' stroke-width='2'/%3E%3C/svg%3E");
    mask-repeat: no-repeat;
    mask-size: initial;
    mask-position: 5px 5px;
  }
}

// ROUTE.GIFT_CARD
.giftcard {
  font-family: var( --main-font );
  // covered
  &__wrapper-covered-description {
    max-width: 246px;
    padding: 16px;
    background: #FFFFFF;
    box-shadow: 0px 5px 25px rgba(31, 102, 241, 0.2);
    @media screen and ( min-width: 900px ) {
      margin-left: -17px;
      padding: 32px;
      align-self: flex-end;
      max-width: 500px;
      height: 218px;
    }
  }
  &__post-subtitle {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 19px;
    font-style: normal;
    font-weight: 700;
    color: var( --textColorSecondary );
  }
  &__post-text {
    display: block;
    max-height: 174px;
    font-size: 16px;
    line-height: 19px;
    font-style: normal;
    font-weight: 700;
    color: var( --textColorMain );
    overflow: auto;

    // firefox scrollbar
    scrollbar-color: #E0E0E0 rgba(224, 224, 224, 0.5);     // цвет ползунка +  цвет скроллбара
    scrollbar-width: thin;

    @media screen and ( min-width: 900px ) {
      max-height: 127px;
      font-size: 18px;
      line-height: 25px;
    }
  }
  // scrollbar for Safari
  &__post-text::-webkit-scrollbar {
    //вся полоса прокрутки
    width: 8px;
    background-color: rgba(224, 224, 224, 0.5);
    border-radius: 2px;
  }
  &__post-text::-webkit-scrollbar-button {
    //кнопки на полосе прокрутки (стрелки, указывающие вверх и вниз, которые прокручивают одну строку за раз)
    display: none;
  }
  &__post-text::-webkit-scrollbar-thumb {
    //перетаскиваемый дескриптор прокрутки
    background-color: #E0E0E0;
    border-radius: 2px;
  }
  &__button-wrapper {
    margin: 24px auto 0;
    @media screen and ( min-width: 900px ) {
      width: 386px;
    }
  }
  &__thank-you-desc {
    margin: 40px auto 24px;
    max-width: 280px;
    font-family: var(--main-font);
    font-size: 16px;
    line-height: 19px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    @media screen and (min-width: 900px) {
      max-width: 100%;
    }
  }
  &__button {
    margin: 0 auto;
    padding: 0;
    width: 228px;
    height: 52px;
    font-size: 16px;
    line-height: 19px;
    font-style: normal;
    font-weight: 700;
    color: #FFFFFF;
    background-color: var( --brand-color );
    border-radius: 4px;
    border: 2px solid transparent;
    box-sizing: border-box;
    outline: none;
    cursor: pointer;
    &:focus-visible {
      border-color: #2D2C2C;
    }
    &:hover {
      filter: saturate(123%);
    }
    @media screen and (min-width: 900px) {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

// ROUTE.GIFT_CARD_THANKS
.giftcard-thanks {
  font-family: var( --main-font );
  &__wrapper-form {
    padding: 16px;
    width: 100%;
    max-width: 336px;
    box-shadow: 0px 0px 24px rgba(95, 95, 95, 0.32);
    border-radius: 8px;
    @media screen and (min-width: 900px) {
      margin: 0 auto;
      padding: 0;
      align-self: flex-start;
      max-width: 400px;
      box-shadow: none;
      border-radius: 0;
    }
  }
  &__legend {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 29px;
    font-style: normal;
    font-weight: 700;
    color: var(--textColorMain);
    @media screen and (min-width: 900px) {
      margin-bottom: 24px;
      font-size: 28px;
      line-height: 34px;
    }
  }
  &__legend-desc {
    margin-bottom: 16px;
    font-size: 15px;
    line-height: 21px;
    font-style: normal;
    font-weight: 400;
    color: var(--textColorMain);
    @media screen and (min-width: 900px) {
      line-height: 19px;
    }
  }
  &__button-disabled {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 52px;
    font-family: inherit;
    font-size: 12px;
    line-height: 14px;
    font-style: normal;
    font-weight: 400;
    color: var(--textColorSecondary);
    background: rgba(224, 224, 224, 0.5);
    border-radius: 4px;
    border: none;
  }
  &__button-checkmark {
    margin-right: 8px;
    width: 15px;
    height: auto;
    object-fit: contain;
  }
}

//alternativesPage
.alternatives {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  background-color: #FFFFFF;
  box-shadow: 0px 5px 25px rgba(31, 102, 241, 0.2);
  border-radius: 4px;
  @media screen and (min-width: 900px) {
    box-shadow: none;
    margin-left: 0;
    margin-right: 0;
  }
  &__orange-warning {
    padding: 10px 25px;
    font-family: var( --main-font );
    font-size: 14px;
    line-height: 17px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    color: var(--textColorMain);
    background-color: #F4C151;
    @media screen and (min-width: 900px) {
      padding: 10px 32px;
    }
  }
  &__content {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    font-family: var( --main-font );
    @media screen and (min-width: 900px) {
      padding-top: 30px;
    }
  }
  &__title {
    margin: 0 0 7px;
    font-size: 20px;
    line-height: 24px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    color: var(--textColorMain);
    @media screen and (min-width: 900px) {
      margin-bottom: 25px;
      font-size: 32px;
      line-height: 38px;
    }
  }
  // ozon
  &__desc {
    padding: 0 10px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
    font-family: var( --main-font );
    font-size: 13px;
    line-height: 14px;
    text-align: center;
    color: #808080;
    @media screen and (min-width: 900px) {
      padding: 0;
      font-size: 14px;
      line-height: 16px;
    }
  }
  //??
  &__button-select {
    display: block;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: auto;
    font-family: var( --main-font );
    font-size: 14px;
    line-height: 20px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    color: #000000;
    border: none;
    background-color: rgba(99, 114, 130, .1);
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    @media screen and (min-width: 900px) {
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 0;
      background-color: #FFFFFF;
      align-self: center;
    }
    &_theme {
      color: var( --brand-color );
    }
  }
}
.oos-gift {
  padding: 5px 10px;
  display: flex;
  align-items: flex-start;
  @media screen and (min-width: 900px) {
    margin-bottom: 10px;
   padding: 0;
  }
  &__image-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-right: 12px;
    @media screen and (min-width: 900px) {
      width: 45px;
      height: 45px;
    }
  }
  &__image {
    width: 100%;
    height: auto;
    max-height: 40px;
    object-fit: contain;
    @media screen and (min-width: 900px) {
      max-height: 45px;
    }
  }
  &__description {
    display: flex;
    flex-direction: column;
  }
  &__description-title {
    margin-bottom: 3px;
    font-family: var( --main-font );
    font-size: 14px;
    line-height: 17px;
    font-style: normal;
    font-weight: 700;
    color: var(--textColorMain);
  }
  &__description-cost {
    font-family: var( --main-font );
    font-size: 14px;
    line-height: 17px;
    font-style: normal;
    font-weight: 700;
    color: #E53659;
  }
}

