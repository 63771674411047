.progress {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-flow: nowrap;
  justify-content: space-between;
  margin: 0 auto 24px;
  width: 212px;
  font-family: var( --main-font );
  @media screen and ( min-width: 900px ) {
    width: 220px;
  }
  &__item {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    flex-basis: 60px;
    @media screen and ( min-width: 900px ) {
      flex-basis: 70px;
    }
  }
  &__number {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    width: 32px;
    height: 32px;
    font-size: 14px;
    line-height: 17px;
    font-style: normal;
    font-weight: 700;
    color: #FFFFFF;
    text-align: center;
    background-color: var(--brand-color);
    border: 1px solid var(--brand-color);
    border-radius: 50%;
    @media screen and ( min-width: 900px ) {
      width: 40px;
      height: 40px;
      font-size: 16px;
      line-height: 19px;
    }
    &_disabled {
      color: var( --brand-color );
      background-color:#FFFFFF;
    }
  }
  &__text {
    font-size: 12px;
    line-height: 14px;
    font-style: normal;
    font-weight: 400;
    color: var( --brand-color );
    text-align: center;
    @media screen and (min-width: 900px) {
      font-size: 14px;
      line-height: 17px;
    }
    &_disabled {
      opacity: .5;
    }
  }
  &__line {
    position: absolute;
    left: 46px;
    top: 16px;
    width: 120px;
    height: 1px;
    background: var( --brand-color );
    @media screen and ( min-width: 900px ) {
      left: 54px;
      top: 20px;
      width: 111px;
    }
  }
  &_size-desktop-margin {
    @media screen and (min-width: 900px) {
      margin-bottom: 0px;
    }
  }
}
