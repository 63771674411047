.textarea {
  position: relative;
  font-family: var( --main-font );
  padding: 15px 3px 3px 11px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  &:hover {
    border-color: var( --textColorSecondary );
  }
  &__box{
    display: block;
    width: 100%;
    padding: 0;
    resize: vertical;
    font-family: var( --main-font );
    font-size: 15px;
    line-height: 19px;
    font-style: normal;
    font-weight: 400;
    text-align: start;
    color: var( --textColorMain );
    border: none;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    overflow: auto;
    transition: all .2s ease;
    outline: none;
    cursor: auto;
    appearance: none;

    // firefox scrollbar
    scrollbar-color: #E0E0E0 rgba(224, 224, 224, 0.5);     // цвет ползунка +  цвет скроллбара
    scrollbar-width: thin;

    &:focus {
      border-color: var( --main-color );
    }
    &:hover {
      border-color: var( --textColorSecondary );
    }
    &:hover + .textarea__label {
      color: var( --textColorMain );
    }
    &:hover + .textarea__label_tiny {
      color: var( --textColorSecondary );
    }
  }
  &__label {
    position: absolute;
    top: 16px;
    left: 12px;
    font-size: 15px;
    line-height: 19px;
    font-style: normal;
    font-weight: 400;
    color: var( --textColorSecondary );
    transition: all 0.2s ease;
    &_tiny {
      top: 4.5px;
      font-size: 11px;
      line-height: 13px;
    }
  }
  &_focus {
    border-color: var( --brand-color );
  }
  &_full-size {
    width: 100%;
  }
  &_half-media-size {
    width: 100%;
    @media screen and (min-width: 900px) {
      width: calc(50% - 4px);
    }
  }
  &_half-size {
    width: calc(50% - 4px);
  }
  &_outer-size {
    margin-bottom: 8px;
    &_16-24 {
      margin-bottom: 16px;
      @media screen and ( min-width: 900px ) {
        margin-bottom: 24px;
      }
    }
  }
}
// scrollbar for Safari
.textarea__box::-webkit-scrollbar {
  //вся полоса прокрутки
  width: 8px;
  background-color: rgba(224, 224, 224, 0.5);
  border-radius: 2px;
}
.textarea__box::-webkit-scrollbar-button {
  //кнопки на полосе прокрутки (стрелки, указывающие вверх и вниз, которые прокручивают одну строку за раз)
  display: none;
}
.textarea__box::-webkit-scrollbar-thumb {
  //перетаскиваемый дескриптор прокрутки
  background-color: #E0E0E0;
  border-radius: 2px;
}
//.textarea__box::-webkit-scrollbar-track {
//  //дорожка (полоса прокрутки) полосы прокрутки, где есть серая полоса поверх белой полосы
//}
//.textarea__box::-webkit-scrollbar-track-piece {
//  //часть дорожки (индикатор выполнения), не закрытая дескриптором
//}
//.textarea__box::-webkit-scrollbar-corner {
//  //нижний угол полосы прокрутки, где встречаются горизонтальная и вертикальная полосы прокрутки. Часто это нижний правый угол окна браузера
//}
//.textarea__box::-webkit-resizer {
// //перетаскиваемый маркер изменения размера, который появляется в нижнем углу некоторых элементов
//}

