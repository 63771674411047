.address {
  position: relative;
  width: 100%;
  font-family: var( --main-font );
  &__input {
    display: flex;
    width: 100%;
    padding: 20px 12px 6px;
    font-size: 14px;
    line-height: 20px;
    font-style: normal;
    font-weight: 400;
    color: var( --textColorMain );
    background-color: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    outline: none;
    appearance: none;
    cursor: text;
    &:hover {
      border-color: var( --textColorSecondary );
    }
    &_invalid {
      border-color: #F91155;
    }
    &_focused {
      border-color: var( --brand-color );
    }
  }
  &__placeholder {
    z-index: 1;
    position: absolute;
    left: 12px;
    top: 14px;
    width: 93%;
    font-family: var( --main-font );
    font-size: 14px;
    line-height: 20px;
    font-style: normal;
    font-weight: 400;
    color: var( --textColorSecondary );
    pointer-events: none;
    transition: .3s ease-in;
    &_focused {
      top: 7px;
      font-size: 11px;
      line-height: 13px;
    }
  }
  &:hover .address__placeholder {
    color: var( --textColorMain );
  }
  &:hover .address__placeholder_focused {
    color: var( --textColorSecondary );
  }
  &_full-size {
    width: 100%;
  }
  &_half-media-size {
    width: 100%;
    @media screen and (min-width: 900px) {
      width: calc(50% - 4px);
    }
  }
  &_half-size {
    width: calc(50% - 4px);
  }
  &_outer-size {
    margin-bottom: 8px;
    &_16-24 {
      margin-bottom: 16px;
      @media screen and ( min-width: 900px ) {
        margin-bottom: 24px;
      }
    }
  }
  // yandex address dropdown style
  .ymaps-2-1-78-suggest-item,
  .ymaps-2-1-79-suggest-item {
    border-bottom: 1px solid #f1f1f1;
    border-top: none;
    &:focus {
      background-color: rgba(0, 0, 0, .16);
    }
  }
  .ymaps-2-1-78-suggest-item-0,
  .ymaps-2-1-79-suggest-item-0 {
    margin-top: 0;
    //border-top: .5px solid rgba(0, 0, 0, .16);
  }
  .ymaps-2-1-79-suggest-item:last-of-type {
    margin-bottom: 0;
    border-bottom: 0;
  }
  .ymaps-2-1-78-search,
  .ymaps-2-1-79-search {
    &__suggest {
      right: 0;
      left: 0;
      top: 2px;
      font-family: var( --main-font );
      font-size: 14px;
      line-height: 17px;
      font-style: normal;
      font-weight: 400;
      color: var( --textColorMain );
      border: none;
      background-clip: border-box;
      box-shadow: 0px 4px 16px rgba(77, 95, 113, 0.16)!important;
      border-radius: 4px;
    }
    &__suggest-item {
      padding: 11.5px 12px;
      &:last-of-type {
        margin-bottom: 0;
      }
      &:hover {
        background-color: #F2F2F2;
      }
      &_selected_yes {
        background-color: #F2F2F2;
      }
    }
  }
  &__error {
    padding-top: 2px;
    font-size: 11px;
    line-height: 13px;
    font-style: normal;
    font-weight: 400;
    color: #F91155;
  }
  // убирает строку 'Loading...' под input при вводе адреса
  .rbt.has-aux {
    font-size: 0;
    line-height: 0;
  }
  // убирает строки 'no matches found' и 'Searching...' под input при вводе адреса
  .dropdown-item.disabled {
    display: none;
  }
}
