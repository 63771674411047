.box-mask {
  display: block;
  height: auto;
  &_theme-recipient {
    margin-bottom: 27px;
    align-self: center;
    width: 100%;
    max-width: 280px;
    @media screen and (min-width: 900px) {
      margin-top: 10px;
      max-width: 400px;
    }
  }
  &_theme-giftcard {
    align-self: center;
    width: 100%;
    max-width: 280px;
    @media screen and (min-width: 900px) {
      max-width: 294px;
      z-index: 1;
    }
  }

}
