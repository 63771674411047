.phone {
  display: block;
  position: relative;

  .only-one-country.react-tel-input {
    .form-control {
      padding-left: 19px;
    }
    .flag-dropdown {
      width: 25px;
      background-color: white;
      opacity: 1;
      visibility: visible;
    }
  }

  .react-tel-input {
    font-family: var( --main-font );
    // hover

    &:hover + .phone__label {
      color: var( --textColorMain );
    }
    &:hover + .phone__label_tiny {
      color: var( --textColorSecondary );
    }
    &:hover .form-control  {
      border-color: var( --textColorSecondary );
    }
    .form-control {
      width: 100%;
      min-height: 48px;
      padding: 19px 0px 6px 12px;
      font-family: var( --main-font );
      font-size: 15px;
      line-height: 21px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0px;
      color: var( --textColorMain );
      border: 1px solid #E0E0E0;
      border-radius: 4px;
      outline: none;
      appearance: none;
      cursor: text;
    }
    .flag-dropdown {
      top: 20px;
      left: 12px;
      width: 36px;
      height: 24px;
      background-color: #FFFFFF;
      border: none;
      border-radius: 4px;
      visibility: hidden;
      opacity: 0;

    }
    .flag-dropdown.open {
      background-color: rgba(20, 20, 20, .08);
      border-radius: 4px;
      .selected-flag {
        background: transparent;
      }
    }
    .selected-flag {
      padding-left: 0;
      width: 36px;
      &:hover {
        background-color: transparent;
      }
      &:focus {
        background-color: transparent;
      }
      .flag {
        width: 15px;
        height: 10px;
        top: 7px;
        left: 4px;
        margin-top: 0;
      }
      .arrow {
        top: 2px;
        left: 22px;
        border-left: 4px solid transparent;
        border-right: 0px solid transparent;
        border-top: 4px solid #555;
      }
      .arrow.up {
        top: 9px;
      }
    }
    .country-list {
      position: fixed;
      top: 20px;
      margin: 0;
      left: 20px;
      width: calc(100vw - 40px);
      min-height: calc(100vh - 40px);
      box-shadow: 0px 0px 0px 30px rgba(20, 20, 20, .3);
      background-color: #FFFFFF;
      border-radius: 4px;

      // Hide scrollbar
      -ms-overflow-style: none; // IE and Edge
      scrollbar-width: none; // Firefox
      &::-webkit-scrollbar {
        width: 0; // Chrome, Safari and Opera
      }
      @media screen and ( min-width: 414px ) {
        left: calc((100vw - 375px)/2);
        width: 375px;
        box-shadow: 0px 0px 0px 270px rgba(20, 20, 20, .3);
      }
      @media screen and (min-width: 900px) {
        position: absolute;
        top: 30px;
        left: -12px;
        width: 196px;
        min-height: 200px;
        max-height: 252px;
        margin-left: 0;
        box-shadow: 0px 4px 16px rgba(77, 95, 113, 0.16);
      }
      .search {
        padding: 12px;
        @media screen and (min-width: 900px) {
          padding-bottom: 0;
        }
        .search-box {
          display: block;
          //width: calc(100% - 10px);
          width: 100%;
          margin: 0;
          padding: 11.5px 12px;
          font-family: var( --main-font );
          font-size: 12px;
          line-height: 14px;
          font-style: normal;
          font-weight: 400;
          text-transform: capitalize;
          color: var( --textColorSecondary );
          background: #F2F2F2;
          border: 1px solid #3678DC;
          border-radius: 4px;
          @media screen and ( min-width: 900px ) {
            font-size: 14px;
            line-height: 17px;
          }
        }
      }
      .search-emoji {
        // убирает элемент "лупа" слева от поля
        display: none;
      }
      .country {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 11.5px 12px;
        font-size: 12px;
        line-height: 14px;
        font-style: normal;
        font-weight: 400;
        border-bottom: 1px solid rgba(224, 224, 224, 0.5);
        @media screen and ( min-width: 900px ) {
          font-size: 14px;
          line-height: 17px;
        }
        &.highlight {
          background-color: rgba(224, 224, 224, 0.5);
        }
      }
      // меняем расположение названия страны, флага и кода
      .flag {
        order: 2;
        margin-top: 0;
        margin-right: 4px;
      }
      .country-name {
        order: 1;
        margin-right: auto;
        font-size: 13px;
        line-height: 1;
        font-weight: 400;
        font-style: normal;
      }
      .dial-code {
        order: 3;
        margin-right: 4px;
      }
    }
  }
  // if focus
  &_focused .react-tel-input {
    .form-control {
      padding-left: 50px;
      color: #000000;
      border-color: var(--brand-color);
      transition: all .2s ease-in;
    }
    .flag-dropdown {
      visibility: visible;
      opacity: 1;
      transition: all .2s ease-in .1s;
    }
  }
  // if value
  &_has-value .react-tel-input {
    .form-control {
      padding-left: 51px;
      color: var( --textColorMain );
    }
    .flag-dropdown {
      visibility: visible;
      opacity: 1;
      background-color: rgba(224, 224, 224, 0.5);
      border-radius: 4px;
    }
  }
  // if invalid
  &_invalid .react-tel-input .form-control {
    border-color: #E13B30;
  }
  &__label {
    position: absolute;
    left: 12px;
    top: 14px;
    width: 93%;
    font-family: var( --main-font );
    font-size: 15px;
    line-height: 21px;
    font-style: normal;
    font-weight: 400;
    color: var( --textColorSecondary );
    pointer-events: none;
    transition: .2s ease-in;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &__label_tiny {
    top: 7px;
    font-size: 11px;
    line-height: 13px;
  }
  &__error {
    padding-top: 2px;
    font-family: var( --main-font );
    font-size: 11px;
    line-height: 13px;
    font-style: normal;
    font-weight: 400;
    color: #E13B30;
  }
  &_full-size {
    width: 100%;
  }
  &_half-media-size {
    width: 100%;
    @media screen and (min-width: 900px) {
      width: calc(50% - 4px);
    }
  }
  &_half-size {
    width: calc(50% - 4px);
  }
  &_outer-size {
    margin-bottom: 8px;
    &_16-24 {
      margin-bottom: 16px;
      @media screen and ( min-width: 900px ) {
        margin-bottom: 24px;
      }
    }
  }
}
