.input {
  display: block;
  position: relative;
  &__label {
    position: absolute;
    left: 12px;
    top: 14px;
    width: 93%;
    font-family: var( --main-font );
    font-size: 15px;
    line-height: 21px;
    font-style: normal;
    font-weight: 400;
    color: var( --textColorSecondary );
    pointer-events: none;
    transition: .2s ease-in;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &:hover .input__label {
    color: var( --textColorMain );
  }
  &__label_tiny {
    top: 7px;
    font-size: 11px;
    line-height: 13px;
  }
  &:hover .input__label_tiny {
    color: var( --textColorSecondary );
  }
  &__element {
    display: flex;
    width: 100%;
    padding: 19px 12px 6px;
    font-family: var( --main-font );
    font-size: 15px;
    line-height: 21px;
    font-style: normal;
    font-weight: 400;
    color: var( --textColorMain );
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    outline: none;
    appearance: none;
    cursor: text;
    &:focus {
      border-color: var( --brand-color );
    }
    &_invalid {
      border-color: #E13B30;
    }
  }
  &:hover .input__element {
    border-color: var( --textColorSecondary );
  }
  &__error {
    padding-top: 2px;
    font-family: var( --main-font );
    font-size: 11px;
    line-height: 13px;
    font-style: normal;
    font-weight: 400;
    color: #E13B30;
  }
  &_full-size {
    width: 100%;
  }
  &_half-media-size {
    width: 100%;
    @media screen and (min-width: 900px) {
      width: calc(50% - 4px);
    }
  }
  &_half-size {
    width: calc(50% - 4px);
  }
  &_outer-size {
    margin-bottom: 8px;
    &_16 {
      margin-bottom: 16px;
    }
    &_16-24 {
      margin-bottom: 16px;
      @media screen and ( min-width: 900px ) {
        margin-bottom: 24px;
      }
    }
  }
}
// removes browser styling
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  //border: 1px;
  -webkit-text-fill-color: var( --textColorMain );
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  //background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(0,174,255,0.04) 50%,rgba(255,255,255,0) 51%,rgba(0,174,255,0.03) 100%);
  background: #FFFFFF;
}
