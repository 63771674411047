.gift {
  display: block;
  font-family: var(--main-font);
  &__option {
    margin-top: 16px;
    margin-bottom: 38px;
    width: 100%;
    @media screen and (min-width: 900px) {
      margin-bottom: 34px;
    }
  }
  // sender
  &__box-ribbon {
    display: none;
    @media screen and (min-width: 900px) {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
    }
  }
  // sender gift box
  &__wrapper-boxView {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 900px) {
      top: 6px;
      left: 58.4px;
      height: 327px;
      width: 248px;
    }
  }
  //recipient gift box
  &__wrapper-fullView {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    min-height: 393px;
    @media screen and ( min-width: 900px ) {
      min-height: 508px;
    }
  }
  // desc gift to carusel recipient flow
  &__legend {
    margin-top: 16px;
    width: 100%;
    font-family: var( --main-font );
    font-size: 12px;
    line-height: 14px;
    font-style: normal;
    font-weight: 400;
    color: var( --textColorMain );
    text-align: center;
    // ...
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media screen and (min-width: 900px) {
      top: 457px;
      width: 400px;
      font-size: 14px;
      line-height: 17px;
    }
  }
  // small display ribbon
  &__gift-ribbon {
    position: absolute;
    top: 0;
    left: 0;
    @media screen and (min-width: 900px) {
      display: none;
    }
  }
  // banner large display
  &__brand-mask-box {
    display: none;
    @media screen and (min-width: 900px) {
      position: absolute;
      top: 44px;
      left: -140px;
      display: block;
      width: 250px;
      height: 250px;
      border-radius: 4px;
      transform: rotate(-30deg);
    }
  }
  &__brand-mask-image {
    display: none;
    @media screen and (min-width: 900px) {
      position: relative;
      display: block;
      max-width: 100%;
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 4px;
    }
  }
  &__thumb-dot {
    cursor: pointer;
    padding: 5px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #E0E0E0;
    mask-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='6' cy='6' r='6' fill='%23E0E0E0'/%3E%3C/svg%3E");
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='6' cy='6' r='6' fill='%23E0E0E0'/%3E%3C/svg%3E");
    mask-size: 12px 12px;
    -webkit-mask-size: 12px 12px;
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    mask-position: center;
    -webkit-mask-position: center;
    &_selected {
      background-color: var(--brand-color);
    }
    &_small {
      padding: 7px;
      mask-size: 8px 8px;
      -webkit-mask-size: 8px 8px;
    }
  }
  // bubbles gifts quantity
  &__quantity {
    position: absolute;
    padding: 4px 10px;
    display: block;
    font-size: 14px;
    line-height: 17px;
    font-style: normal;
    font-weight: 400;
    color: #FFFFFF;
    background-color: var(--brand-color);
    border-radius: 20px;
    &_boxView {
      top: 33px;
      right: 8px;
      @media screen and (min-width: 900px) {
        top: 60px;
        right: 65px;
      }
    }
    &_fullView {
      top: 33px;
      right: 12px;
      @media screen and (min-width: 900px) {
        top: 29px;
        right: -14px;
      }
    }
  }

  &_hidden {
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
  // sender
  &_theme-boxView {
    flex-shrink: 0;
    position: relative;
    margin: 0 auto 19px;
    width: 100%;
    height: 337px;
    overflow: hidden;
    @media screen and (min-width: 900px) {
      width: 360px;
      height: 375px;
      margin: 0;
      margin-right: 20px;
      padding-top: 0;
      align-self: flex-start;
    }
  }
  // recipient
  &_theme-fullView {
    flex-shrink: 0;
    position: relative;
    margin: 0 auto 19px;
    width: 332px;
    @media screen and (min-width: 900px) {
      width: 400px;
      margin: 0;
      margin-right: 60px;
      align-self: flex-start;
    }
  }
  // giftcard
  &_theme-giftcard {
    position: relative;
    margin: 0 auto;
    width: 332px;
    @media screen and (min-width: 900px) {
      width: 400px;
      margin: 0;
      align-self: flex-start;
    }
  }
}
//carusel

// variant === boxView {sender flow}
.boxView {
  height: 100%;
  @media screen and (min-width: 900px) {
    height: auto;
  }
  .carousel .slider-wrapper.axis-horizontal .slider {
    align-items: center;
  }
  .carousel.carousel-slider {
    position: static;
    margin-top: 44px;
    width: 260px!important;
    height: 260px;
    @media screen and (min-width: 900px) {
      margin-top: 0;
      width: 198px!important;
      height: 198px;
    }
  }
  // стрелочки
  .carousel.carousel-slider .control-arrow {
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
    padding: 0;
    background-color: transparent;
    opacity: 1;
    @media screen and (min-width: 900px) {
      top: auto;
      transform: none;
      bottom: -15px;
    }
  }
  .carousel.carousel-slider .control-arrow:hover {
    background: none;
  }
  .carousel.carousel-slider .control-arrow:before {
    margin: 0;
    border: none;
    display: block;
    width: 12px;
    height: 21px;
    @media screen and (min-width: 900px) {
      width: 10px;
      height: 18px;
    }
  }
  .carousel .control-prev.control-arrow {
    padding: 9px 9px 9px 0;
    @media screen and (min-width: 900px) {
      padding: 11px 15px 11px 15px;
      left: -9px;
    }
  }
  .carousel .control-prev.control-arrow:before {
    background-color: var(--brand-color);
    mask-image: url("data:image/svg+xml,%3Csvg width='12' height='21' viewBox='0 0 12 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.114 2.036 9.43.266 0 10.166l9.429 9.9 1.685-1.77-7.743-8.13 7.743-8.13Z' fill='%23E0E0E0'/%3E%3C/svg%3E%0A");
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='12' height='21' viewBox='0 0 12 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.114 2.036 9.43.266 0 10.166l9.429 9.9 1.685-1.77-7.743-8.13 7.743-8.13Z' fill='%23E0E0E0'/%3E%3C/svg%3E%0A");
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    mask-size: contain;
    -webkit-mask-size: contain;
  }
  .carousel .control-next.control-arrow {
    padding: 9px 0 9px 9px;
    @media screen and (min-width: 900px) {
      padding: 11px 15px 11px 15px;
    }
  }
  .carousel .control-next.control-arrow:before {
    background-color: var(--brand-color);
    mask-image: url("data:image/svg+xml,%3Csvg width='12' height='21' viewBox='0 0 12 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m.886 18.964 1.685 1.77 9.429-9.9-9.429-9.9-1.685 1.77 7.743 8.13-7.743 8.13Z' fill='%23E0E0E0'/%3E%3C/svg%3E");
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='12' height='21' viewBox='0 0 12 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m.886 18.964 1.685 1.77 9.429-9.9-9.429-9.9-1.685 1.77 7.743 8.13-7.743 8.13Z' fill='%23E0E0E0'/%3E%3C/svg%3E");
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    mask-size: contain;
    -webkit-mask-size: contain;
  }
  .carousel {
    position: static;
    // контейнер кружочков
    .control-dots {
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      width: auto;
      margin: 0;
      @media screen and (min-width: 900px) {
        bottom: -6px;
      }
    }
    .slider-wrapper {
      height: 100%;
    }
    .slider {
      height: 100%;
    }
    img {
      width: 100%;
      max-width: 260px;
      height: auto;
      max-height: 260px;
      object-fit: contain;
      @media screen and (min-width: 900px) {
        max-width: 198px;
        max-height: 198px;
      }
    }
    .carousel-status {
      left: 50%;
      right: auto;
      transform: translateX(-50%);
      margin: 0;
      padding: 0;
      font-family: var( --main-font );
      font-size: 20px;
      line-height: 24px;
      font-style: normal;
      font-weight: 700;
      color: var(--textColorMain);
      text-shadow: none;
      white-space: nowrap;
    }
  }
}

  // variant === fullView {recipient flow}
.fullView {
  height: 100%;
  @media screen and (min-width: 900px) {
    height: auto;
  }
  .carousel.carousel-slider {
    position: static;
    margin-top: 44px;
    width: 280px!important;
    height: 280px;
    @media screen and (min-width: 900px) {
      margin-top: 40px;
      width: 400px!important;
      height: 400px;
    }
  }
  // стрелочки
  .carousel.carousel-slider .control-arrow {
    top: 162px;
    bottom: auto;
    padding: 0;
    background-color: transparent;
    opacity: 1;
    @media screen and (min-width: 900px) {
      top: auto;
      transform: none;
      bottom: -12px;
    }
  }
  .carousel.carousel-slider .control-arrow:hover {
    background: none;
  }
  .carousel.carousel-slider .control-arrow:before {
    margin: 0;
    border: none;
    display: block;
    width: 12px;
    height: 21px;
    @media screen and (min-width: 900px) {
      width: 10px;
      height: 18px;
    }
  }
  .carousel .control-prev.control-arrow {
    padding: 9px 9px 9px 0;
    @media screen and (min-width: 900px) {
      padding: 11px 15px 11px 15px;
      left: -10px;
    }
  }
  .carousel .control-prev.control-arrow:before {
    background-color: var(--brand-color);
    mask-image: url("data:image/svg+xml,%3Csvg width='12' height='21' viewBox='0 0 12 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.114 2.036 9.43.266 0 10.166l9.429 9.9 1.685-1.77-7.743-8.13 7.743-8.13Z' fill='%23E0E0E0'/%3E%3C/svg%3E%0A");
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='12' height='21' viewBox='0 0 12 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.114 2.036 9.43.266 0 10.166l9.429 9.9 1.685-1.77-7.743-8.13 7.743-8.13Z' fill='%23E0E0E0'/%3E%3C/svg%3E%0A");
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    mask-size: contain;
    -webkit-mask-size: contain;
  }
  .carousel .control-next.control-arrow {
    padding: 9px 0 9px 9px;
    @media screen and (min-width: 900px) {
      padding: 11px 15px 11px 15px;
      right: -10px;
    }
  }
  .carousel .control-next.control-arrow:before {
    background-color: var(--brand-color);
    mask-image: url("data:image/svg+xml,%3Csvg width='12' height='21' viewBox='0 0 12 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m.886 18.964 1.685 1.77 9.429-9.9-9.429-9.9-1.685 1.77 7.743 8.13-7.743 8.13Z' fill='%23E0E0E0'/%3E%3C/svg%3E");
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='12' height='21' viewBox='0 0 12 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m.886 18.964 1.685 1.77 9.429-9.9-9.429-9.9-1.685 1.77 7.743 8.13-7.743 8.13Z' fill='%23E0E0E0'/%3E%3C/svg%3E");
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    mask-size: contain;
    -webkit-mask-size: contain;
  }
  .carousel {
    position: static;
    // контейнер кружочков
    .control-dots {
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      width: auto;
      margin: 0;
      // кружочек
      @media screen and (min-width: 900px) {
        bottom: -4px;
      }
    }
    .slider-wrapper {
      height: 100%;
    }
    .slider {
      height: 100%;
    }
    .slide {
      height: 280px;
      @media screen and (min-width: 900px) {
        height: 100%;
      }
      div {
        display: flex;
        align-self: center;
        height: 100%;
      }
      img {
        object-fit: contain;
        height: auto;
        @media screen and (min-width: 900px) {
          max-height: 400px;
        }
      }
    }
    .carousel-status {
      left: 50%;
      right: auto;
      transform: translateX(-50%);
      margin: 0;
      padding: 0;
      font-family: var( --main-font );
      font-size: 20px;
      line-height: 24px;
      font-style: normal;
      font-weight: 700;
      color: var(--textColorMain);
      text-shadow: none;
      white-space: nowrap;
    }
  }
}
