.share-popup {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 15.5px 0;
  font-family: var( --main-font );
  box-shadow: 0px 5px 25px rgba(31, 102, 241, 0.2);
  @media screen and ( min-width: 900px ) {
    flex-direction: row;
    justify-content: space-between;
    padding: 14px 40px;
  }
  &__preview {
    display: none;
    @media screen and ( min-width: 900px ) {
      display: flex;
      align-items: center;
    }
  }
  &__preview-icon {
    margin-right: 16px;
    width: 24px;
    height: auto;
    object-fit: contain;
  }
  &__preview-text {
    margin: 0;
    font-size: 14px;
    line-height: 17px;
    font-style: normal;
    font-weight: 400;
    color: var( --textColorSecondary );
  }
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    padding: 0 32px;
    min-height: 52px;
    font-family: inherit;
    font-size: 18px;
    line-height: 22px;
    font-style: normal;
    font-weight: 700;
    color: #FFFFFF;
    background-color: #67CE00;
    border-radius: 4px;
    border: 2px solid transparent;
    box-sizing: border-box;
    @media screen and ( min-width: 900px ) {
      margin-bottom: 0;
      order: 3;
    }
    &:focus-visible {
      border-color: #2D2C2C;
    }
  }
  &__button-image {
    margin-left: 16px;
    width: 24px;
    height: 24px;
    object-fit: contain;
    order: 2;
  }
  &__title {
    margin: 0;
    font-size: 18px;
    line-height: 22px;
    font-style: normal;
    font-weight: 700;
    color: var( --textColorMain );
    @media screen and ( min-width: 900px ) {
      font-size: 28px;
      line-height: 34px;
    }
  }
  &__wrapper-for-fixed-width {
    display: flex;
    flex-direction: column;
    font-family: var( --main-font );
    box-shadow: 0px 5px 25px rgba(31, 102, 241, 0.2);
    @media screen and ( min-width: 900px ) {

    }
  }
  &__legend {
    margin-top: 0;
    margin-bottom: 4px;
    font-size: 18px;
    line-height: 22px;
    font-style: normal;
    font-weight: 700;
    color: var( --textColorMain );
    text-align: center;
    @media screen and ( min-width: 900px ) {
      font-size: 28px;
      line-height: 34px;
    }
  }
  &__legend-desc {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 19px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    color: var( --textColorMain );
    @media screen and ( min-width: 900px ) {
      font-size: 18px;
      line-height: 22px;
    }
  }
  &__social-wrap {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 400px;
  }
  // box with social button
  &__social-box {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    @media screen and ( min-width: 900px ) {
      max-width: 400px;
    }
  }
  &__social-button {
    width: 36px;
    height: 36px;
    padding: 6px;
    background-color: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    cursor: pointer;
    appearance: none;
  }
  &__social-icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  // here modal style
  &__float-block {
    position: fixed;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    opacity: 0;

    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: inherit;
    font-size: 18px;
    line-height: 22px;
    font-style: normal;
    font-weight: 700;

    color: var(--textColorMain);
    background-color: #E0E0E0;
    border: 1px solid rgba(45, 44, 44, 0.2);
    border-radius: 8px;
    @media screen and (min-width: 900px) {
      top: 20px;
      left: auto;
      right: 80px;
      transform: translateX(0);
    }
    &_showed {
      transition: all .3s linear 0s;
      opacity: 1;
      z-index: 100;

      width: calc(100% - 24px);
      max-width: 328px;
      @media screen and (min-width: 900px) {
        width: auto;
      }
    }
    &_link-copied-image {
      margin-right: 8px;
    }
  }

  &__modal-wrapper {
    display: block;
    width: 100%;
    max-width: 328px;
    @media screen and ( min-width: 900px ) {
      margin: 0 auto;
      max-width: 610px;
    }
  }
  &__modal-title-wrapper {
    margin-bottom: 8px;
    display: block;
    width: calc(100% - 30px);
    @media screen and (min-width: 900px) {
      margin: 0 auto 12px;
      width: 100%;
      max-width: 450px;
      text-align: center;
    }
  }
  &__modal-title {
    margin: 0;
    display: inline;
    font-size: 18px;
    line-height: 22px;
    font-style: normal;
    font-weight: 700;
    color: var(--textColorMain);
    @media screen and (min-width: 900px) {
      font-size: 32px;
      line-height: 38px;
    }
  }
  &__modal-social-block {
    display: none;
    @media screen and (min-width: 900px) {
      display: inline;
      width: 101px;
      height: auto;
      object-fit: cover;
      vertical-align: bottom;
    }
  }
  &__modal-desc {
    margin: 0;
    font-size: 15px;
    line-height: 19px;
    font-style: normal;
    font-weight: 400;
    color: var(--textColorSecondary);
    @media screen and (min-width: 900px) {
      margin-bottom: 12px;
      text-align: center;
    }
  }
  &__native-share-button {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 52px;

    font-family: inherit;
    font-size: 16px;
    line-height: 19px;
    font-style: normal;
    font-weight: 700;
    color: #FFFFFF;
    background-color: var(--brand-color);
    border-radius: 4px;
    border: 2px solid transparent;
    box-sizing: border-box;
    cursor: pointer;
    outline: none;
    @media screen and (min-width: 900px) {
      margin-left: 27px;
      padding-right: 20px;
      padding-left: 20px;
      width: auto;
    }
    &:focus-visible {
      border-color: #2D2C2C;
    }
  }
  &__native-share-button-image {
    margin-left: 10px;
  }
  &__fb-share {
    display: block;
  }
  &__fb-share-title {
    margin-top: 0;
    margin-bottom: 12px;
    font-size: 20px;
    line-height: 24px;
    font-style: normal;
    font-weight: 700;
    color: var(--textColorMain);
    text-align: center;
  }
  &__fb-share-button {
    margin: 0 auto;
    padding: 8px 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: inherit;
    font-size: 16px;
    line-height: 19px;
    font-style: normal;
    font-weight: 700;
    color: #FFFFFF;

    background-color: #3479EA;
    border-radius: 4px;
    border: 2px solid transparent;
    box-sizing: border-box;
    cursor: pointer;
    outline: none;
    &:focus-visible {
      border-color: #2D2C2C;
    }
  }
  &__fb-share-button-image {
    margin-right: 8px;
  }

}
