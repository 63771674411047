.info {
  display: block;
  font-family: var( --main-font );
  &__back-link {
    position: absolute;
    top: -37px;
    left: 6px;
    display: block;
    width: 24px;
    height: 24px;
    background-color: var( --brand-color );
    mask-image: url('../../../image/back-arrow.svg');
    -webkit-mask-image: url('../../../image/back-arrow.svg');
    cursor: pointer;
    outline: none;
    @media all and  ( min-width: 900px ){
      top: 8px;
      left: 0;
    }
    &:focus-visible {
      background-color: #2D2C2C;
    }
    &:hover {
      filter: saturate(123%);
    }
    &_rebranding {
      top: -56px;
      left: 9px;
      @media all and  ( min-width: 900px ){
        top: 8px;
        left: 0;
      }
    }
  }
  &__title {
    display: block;
    margin-top: 0;
    margin-bottom: 4px;
    font-size: 24px;
    line-height: 29px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    color: var( --textColorMain );
    outline: none;
    @media screen and ( min-width: 900px ) {
      padding: 0 32px;
      margin-bottom: 8px;
      font-size: 32px;
      line-height: 38px;
    }
    &_variant-smaller-font {
      font-size: 24px;
      line-height: 29px;
      font-style: normal;
      font-weight: 700;
      @media screen and (min-width: 900px) {
        font-size: 28px;
        line-height: 34px;
      }
    }
    &_variant-giftcard {
      margin-bottom: 16px;
      font-size: 24px;
      line-height: 29px;
      @media screen and (min-width: 900px) {
        font-size: 28px;
        line-height: 34px;
      }
    }
  }
  &__desc {
    margin: 0;
    font-family: var( --main-font );
    font-size: 15px;
    line-height: 21px;
    font-style: normal;
    font-weight: 400;
    color: var( --textColorSecondary );
    text-align: center;
    &_variant-giftcard {
      font-size: 16px;
      line-height: 19px;
      font-weight: 700;
      @media screen and (min-width: 900px) {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
  &_theme-default {
    position: relative;
    margin-bottom: 24px;
    @media screen and ( min-width: 900px ) {
      margin-bottom: 40px;
    }
  }
  &_theme-giftcard {
    padding-top: 40px;
  }
  &_bold-desc-giftcard {
    margin-bottom: 8px;
    color: var(--textColorMain);
  }
  // only recipient delivery info page
  &_outer-size {
    &_24 {
      margin-bottom: 24px;
    }
  }


  //\/ for share page  - different locale desktop/mobile
  &_big-content {
    @media screen and (max-width: 900px) {
      display: none;
    }
    .info__back-link {
      top: 6px;
    }
  }
  &_small-content {
    @media screen and (min-width: 900px) {
      display: none;
    }
  }
}

